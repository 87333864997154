import React from "react";
import $ from 'jquery';
import MetaTags from "react-meta-tags";
const mobile = require('is-mobile');

const _resumeService = require("../../services/resume");

class Resume extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: false
        }

    }

    componentDidMount() {
        if(mobile.isMobile()) {
            $('#profileLarge').hide();
        }
        this.getResume();
        this.getSkills();
    }

    getResume = () => {
        _resumeService.getResume()
            .then((response) => {
                if (response) {
                    let state = {
                        status: true,
                        experience: response.data.experience,
                        education: response.data.education
                    };
                    this.setState(state);
                }
            })
    };

    getSkills = () => {
        _resumeService.getSkills()
            .then((response) => {
                if (response) {
                    let state = {
                        skills: response.data.skills,
                        languages: response.data.languages
                    };
                    this.setState(state);
                }
            })
    };


    renderItem = (data) => {
        return (
            <div className="resume-items">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index}
                                 className={index === 0 ? 'resume-item border-line-h active' : 'resume-item border-line-h'}>
                                <div className="date">{item.start} - {item.end}</div>
                                <div className="name">{item.company}</div>
                                <div className="company">{item.position}</div>
                                <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    renderLanguages = (data) => {
        return (
            <ul>
                {
                    data.map((item, index) => {
                        return (
                            <li key={index} className="border-line-h">
                                <div className="name">
                                    {item.name}
                                </div>
                                <div className={'progress p' + item.percent * 10}>
                                    <div className="percentage" style={{width: item.percent * 10 + '%'}}>&nbsp;</div>
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        );
    };

    renderSkills = (data) => {
        return (
            <ul>
                {
                    data.map((item, index) => {
                        return (
                            <li key={index} className="border-line-h">
                                <div className="name">
                                    <span>{item.name}</span>
                                </div>
                                <div className={'progress p' + item.percent * 10}>
                                    <div className="percentage" style={{width: item.percent * 10 + '%'}}>&nbsp;</div>
                                    <span> <i className={'skill_icon ' + item.icon}>&nbsp;</i></span>
                                    <div className="slice">
                                        <div className="bar">&nbsp;</div>
                                        <div className="fill">&nbsp;</div>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        );
    };

    render() {
        return (
            <div className="card-inner animated fadeInLeft" id="card-resume">
                {
                    this.state.status ?

                        <div className="card-wrap">

                            <MetaTags>
                                <title>Resume - Karen Hamazaspyan</title>
                                <meta property="og:title" content="Resume - Karen Hamazaspyan" />
                            </MetaTags>

                            <div className="content resume">
                                <div className="title">Resume</div>
                                <div className="intro">
                                    <h5 style={{'padding-top': '15px'}}>Why i am the right person for the job</h5>
                                    <ul style={{'margin-top': '10px'}}>
                                        <li>Experience in architecture, design, and implementation of enterprise web applications</li>
                                        <li>6 years of software development using OOP concepts and known design patterns</li>
                                        <li>6 years of PHP and JavaScript web development</li>
                                        <li>5 years of MySQL database design and data management</li>
                                        <li>Aspire to achieve the best results by applying the technical and analitical skills</li>
                                        <li>Ability to work on multiple tasks at the same time</li>
                                        <li>A dedicated and hard working team player</li>
                                        <li>Quick learner of new languages and technologies</li>
                                    </ul>
                                </div>
                                <div className="row">
                                    <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                                        <div className="resume-title border-line-h">
                                            <div className="icon"><i className="ion ion-briefcase">&nbsp;</i></div>
                                            <div className="name">Experience</div>
                                        </div>
                                        {
                                            this.renderItem(this.state.experience)
                                        }
                                    </div>
                                    <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                                        <div className="resume-title border-line-h">
                                            <div className="icon"><i className="ion ion-university">&nbsp;</i></div>
                                            <div className="name">EDUCATION</div>
                                        </div>
                                        {
                                            this.renderItem(this.state.education)
                                        }
                                    </div>
                                    <div className="clear">&nbsp;</div>
                                </div>
                            </div>
                            <div className="content skills">
                                <div className="title">My Skills</div>
                                <div className="row">
                                    <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                                        <div className="skills-list percent">
                                            <div className="skill-title border-line-h">
                                                <div className="icon"><i className="ion ion-code">&nbsp;</i></div>
                                                <div className="name">Coding</div>
                                            </div>
                                            <ul>
                                                <li className="border-line-h">
                                                    <div className="name">
                                                        Backend Development
                                                    </div>
                                                    <div className={'progress p90'}>
                                                        <div className="percentage" style={{width: '90%'}}>&nbsp;</div>
                                                    </div>
                                                </li>
                                                <li className="border-line-h">
                                                    <div className="name">
                                                        Frontend Development
                                                    </div>
                                                    <div className={'progress p80'}>
                                                        <div className="percentage" style={{width: '80%'}}>&nbsp;</div>
                                                    </div>
                                                </li>
                                                <li className="border-line-h">
                                                    <div className="name">
                                                        Mobile App Development
                                                    </div>
                                                    <div className={'progress p50'}>
                                                        <div className="percentage" style={{width: '50%'}}>&nbsp;</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col col-d-6 col-t-6 col-m-12 border-line-v">
                                        <div className="skills-list percent">
                                            <div className="skill-title border-line-h">
                                                <div className="icon"><i className="ion ion-flag"></i></div>
                                                <div className="name">Languages</div>
                                            </div>
                                            {
                                                this.state.languages ?
                                                    this.renderLanguages(this.state.languages)
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
                                        <div className="skills-list circles">
                                            <div className="skill-title border-line-h">
                                                <div className="icon"><i className="ion ion-code"></i></div>
                                                <div className="name">Coding</div>
                                            </div>
                                            {
                                                this.state.skills ?
                                                    this.renderSkills(this.state.skills)
                                                    : null
                                            }
                                        </div>
                                    </div>

                                    <div className="clear">&nbsp;</div>
                                </div>
                            </div>
                        </div>

                        :
                        null
                }
            </div>
        );
    }
}

export default Resume;
