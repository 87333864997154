import React from "react";
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
import MetaTags from "react-meta-tags";
const mobile = require('is-mobile');

const _contactService = require("../../services/contact");
const _profileService = require("../../services/profile");

class Contacts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: false,
            formFields: {
                name: '',
                email: '',
                message: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if(mobile.isMobile()) {
            $('#profileLarge').hide();
        }
        this.getProfile();
    }

    handleChange(event) {
        let formFields = {...this.state.formFields};
        formFields[event.target.name] = event.target.value;
        this.setState({
            formFields
        });
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    handleSubmit(event) {
        event.preventDefault();
        $('form#contactForm input, form#contactForm textarea').css('border', 'none');
        let error = false;
        if(this.state.formFields.name === '') {
            $('[name="name"]').css('border', '1px solid red');
            error = true;
        }
        if(this.state.formFields.email === '') {
            $('[name="email"]').css('border', '1px solid red');
            error = true;
        }
        if(this.state.formFields.message === '') {
            $('[name="message"]').css('border', '1px solid red');
            error = true;
        }
        if(error) {
            return false;
        }
        this.sendRequest(this.state.formFields);
    }

    sendRequest = (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('email', data.email);
        formData.append('message', data.message);
        _contactService.sendContact(formData)
            .then((response) => {
                const state = {
                    formFields: {
                        name: '',
                        email: '',
                        message: ''
                    }
                };
                this.setState(state);
                document.getElementById('contactForm').reset();

                toast('🦄 Thanks for contacting me, Your message has been automatically ignored!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch((error) => {
                console.log(error);
            })
    };

    getProfile = () => {
      _profileService.getProfileData()
          .then((response) => {
              const state = this.state;
              state.profile = response.data;
              state.status = true;
              this.setState(state);
              console.log(this.state);
          })
          .catch((error) => {
              console.log(error);
          })
    };

    render() {
        return (
            <div className="card-inner animated fadeInLeft" id="card-contacts">
                {
                    this.state.status
                    ?
                        <div className="card-wrap">

                            <MetaTags>
                                <title>Contacts - Karen Hamazaspyan</title>
                                <meta property="og:title" content="Contacts - Karen Hamazaspyan" />
                            </MetaTags>

                            <div className="content contacts">
                                <div className="title">Get in Touch</div>
                                <div className="row">
                                    <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
                                        <div className="info-list">
                                            <ul>
                                                <li><strong>Address:</strong> {this.state.profile.location.country}/{this.state.profile.location.city}</li>
                                                <li><strong><i className="fab fa-telegram">&nbsp;</i></strong> <a rel="noopener noreferrer"  target="_blank" href="https://t.me/khamazaspyan">@khamazaspyan</a> </li>
                                                <li><strong>Email:</strong> {this.state.profile.email}</li>
                                                <li><strong><i className="fab fa-skype">&nbsp;</i></strong> <a rel="noopener noreferrer"  target="_blank" href="skype:khamazaspyan">ID: khamazaspyan</a> </li>
                                                <li><strong>Phone:</strong> {this.state.profile.phone}</li>
                                                <li><strong><i className="fab fa-whatsapp">&nbsp;</i></strong> <a rel="noopener noreferrer"  target="_blank" href="https://t.me/khamazaspyan">+37455379727</a> </li>


                                            </ul>
                                        </div>
                                    </div>
                                    <div className="clear">&nbsp;</div>
                                </div>
                            </div>

                            <div className="content contacts">
                                <div className="title">Let's work together.</div>
                                <div className="row">
                                    <div className="col col-d-12 col-t-12 col-m-12" style={{'padding-bottom': 0}}>
                                        <p>
                                            If you have a project that you want to get started, think you need my help with something or just fancy saying hey, then get in touch.
                                        </p>
                                    </div>
                                    <div className="col col-d-12 col-t-12 col-m-12 border-line-v">

                                        <div className="">
                                            <div role="form">
                                                <div className="screen-reader-response">&nbsp;</div>
                                                <form id="contactForm" onSubmit={this.handleSubmit} method="post"
                                                      noValidate="novalidate">
                                                    <div className="row">
                                                        <div className="col col-d-6 col-t-6 col-m-12">
                                                            <div className="group-val"><span
                                                                className="wpcf7-form-control-wrap your-name">
                                                         <input name="name" placeholder="Your name" type="text" value={this.state.name} onChange={this.handleChange} /></span>
                                                            </div>
                                                        </div>
                                                        <div className="col col-d-6 col-t-6 col-m-12">
                                                            <div className="group-val"><span
                                                                className="wpcf7-form-control-wrap your-email">
                                                        <input name="email" placeholder="Email Address" type="text" value={this.state.email} onChange={this.handleChange} />
                                                    </span></div>
                                                        </div>
                                                        <div className="col col-d-12 col-t-12 col-m-12">
                                                            <div className="group-val"><span
                                                                className="wpcf7-form-control-wrap your-message">
                                                        <textarea name="message" placeholder="Your Message" value={this.state.message} onChange={this.handleChange}></textarea>
                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="align-left">
                                                        <button type="submit" className="button"><span
                                                            className="text">Send Message</span><span className="arrow"></span>
                                                        </button>
                                                    </div>
                                                    <div className="wpcf7-response-output wpcf7-display-none">&nbsp;</div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear">&nbsp;</div>
                                </div>
                            </div>

                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnVisibilityChange={false}
                                draggable
                                pauseOnHover
                            />
                        </div>

                        :
                        null
                }

            </div>
        );
    }
}

export default Contacts;
