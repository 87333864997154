import React from "react";
import $ from "jquery";
const mobile = require('is-mobile');
const _blogService = require("../../services/blog");

class BlogItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: false
        };
    }

    componentDidMount() {
        if(mobile.isMobile()) {
            $('#profileLarge').hide();
        }
        if(this.props.match.params.id) {
            this.getBlogData(this.props.match.params.id);
        }

    }

    getBlogData = (id) => {
        _blogService.getBlogData(id)
            .then((response) => {
                if(response) {
                    const state = {
                        status: true,
                        data: response
                    };

                    this.setState(state);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    render() {
        return (
            <div>Blog item</div>
        );
    }
}

export default BlogItem;
