import React from "react";
import {NavLink} from "react-router-dom";
import $ from "jquery";
import MetaTags from "react-meta-tags";
const mobile = require('is-mobile');

const _blogService = require("../../services/blog");

class Blog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: false
        };
    }

    componentDidMount() {
        if(mobile.isMobile()) {
            $('#profileLarge').hide();
        }
        this.getBlog();
    }

    getBlog = () => {
        _blogService.getBlog()
            .then((response) => {
                if (response) {
                    const state = {
                        status: true,
                        data: response.data
                    };
                    this.setState(state);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    renderBlog = (data) => {
        return (
            <div className="row border-line-v">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className="col col-d-6 col-t-6 col-m-12">
                                <div className="box-item">
                                    <div id={'post-' + item.id}
                                         className="post type-post status-publish format-standard has-post-thumbnail hentry category-design category-music tag-code tag-html tag-plugin tag-web">
                                        <div className="image">
                                            <a className="post-thumbnail"
                                                                  href="2018/11/16/creativity-is-more-than-a-song/index.html"
                                                                  aria-hidden="true" tabIndex="-1">
                                                {
                                                    item.image ?
                                                        <img
                                                            width="590"
                                                            height="330"
                                                            src="wp-content/uploads/2018/11/blog1-590x330.jpg"
                                                            className="attachment-ryancv_590x330 size-ryancv_590x330 wp-post-image"
                                                            alt="Creativity Is More Than"/>
                                                            :
                                                        null
                                                }

                                            <span className="info"> <span
                                                className="ion ion-document-text"></span>&nsbp;</span> </a>
                                        </div>
                                        <div className="desc">
                                        <NavLink to={'/blog/' + item.alias} className="name">{item.title}</NavLink>
                                        </div>
                                        <div className="text">
                                            {item.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                <div className="clear">&nbsp;</div>
            </div>
        );
    };

    render() {
        return (
            <div className="card-inner  animated fadeInLeft" id="card-blog">
                {
                    this.state.status ?
                        <div className="card-wrap">
                            <MetaTags>
                                <title>Blog - Karen Hamazaspyan</title>
                                <meta property="og:title" content="Blog - Karen Hamazaspyan" />
                            </MetaTags>
                            {
                                this.state.data.length > 0 ?
                                    <div className="content blog">
                                        <div className="title">Blog</div>
                                        {
                                            this.renderBlog(this.state.data)
                                        }
                                        <div className="pager">
                                            <nav className="navigation pagination" role="navigation"><h2
                                                className="screen-reader-text">&nbsp;</h2>
                                                <div className="nav-links"><span aria-current='page'
                                                                                 className='page-numbers current'>1</span>
                                                    <a
                                                        className='page-numbers' href='page/2/index.html#blog'>2</a> <a
                                                        className="next page-numbers"
                                                        href="page/2/index.html#blog">Next</a></div>
                                            </nav>
                                        </div>
                                    </div>
                                    :
                                    <div className="content blog">
                                        <div className="title">Blog</div>
                                        <div className="row border-line-v">
                                            <p style={{padding: 15 + 'px'}}>Blog is empty</p>
                                        </div>
                                    </div>
                            }

                        </div>
                        :
                        null
                }

            </div>
        );
    }
}

export default Blog;
