import axios from "axios";
const env = require('../config/config');
const service_id = 'profile';

export const getProfileData = () => {
    return axios.get(env.API_URL + service_id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};
