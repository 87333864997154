import axios from "axios";
const env = require('../config/config');
const service_id = 'resume';
const skills_id = 'skill';

export const getResume = () => {
    return axios.get(env.API_URL + service_id)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getSkills = () => {
  return axios.get(env.API_URL + skills_id)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
          console.log(error);
      });
};
