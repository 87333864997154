import React from "react";
import $ from 'jquery';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        const config = JSON.parse(localStorage.getItem('config'));

        this.state = {
            href: '#',
            isDark: (config.dark === 1) ? 'active' : '',
            isLight: (config.dark === 0) ? 'active' : ''
        };
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    changeMode(mode) {
        const config = JSON.parse(localStorage.getItem('config'));

        config.dark = (mode === 'dark') ? 1 : 0;
        const element = $('#dark');
        if(mode === 'dark') {
            element.attr('href', 'assets/css/dark.css');
            this.setState({
                isDark: 'active',
                isLight: ''
            });
        }
        else {
            element.attr('href', 'light.css');
            this.setState({
                isDark: '',
                isLight: 'active'
            });
        }
        $('.content-sidebar .close').click();

        localStorage.setItem('config', JSON.stringify(config));
    }

    changeColor(color) {
        const element = $('#color_style');
        element.attr('href', 'assets/css/colors/' + color + '.css');
        const config = JSON.parse(localStorage.getItem('config'));
        config.color = color;
        localStorage.setItem('config', JSON.stringify(config));
        $('.content-sidebar .close').click();
    }

    render() {
        return (
            <aside id="secondary" className="widget-area">
                <section id="recent-posts-2" className="widget widget_recent_entries">
                    <h2 className="widget-title">Settings</h2>
                </section>

                <div className="theme_menu">

                    <div className="clear">&nbsp;</div>
                    <h4>Change Color</h4>
                    <div className="segment">
                        <ul className="theme layout_style">
                            <li>
                                <a onClick={() => this.changeColor('green')} title="theme-green" href={this.state.href} data-color="green"
                                style={{backgroundColor: '#78cc6d'}}>&nbsp;</a>
                            </li>
                            <li>
                                <a onClick={() => this.changeColor('blue')} title="theme-blue" href={this.state.href} data-color="blue"
                                   style={{backgroundColor: 'rgba(8, 86, 193, 1)'}}>&nbsp;</a>
                            </li>
                            <li>
                                <a onClick={() => this.changeColor('orange')} title="theme-orange"href={this.state.href} data-color="orange"
                                   style={{backgroundColor: 'rgba(255, 152, 0, 1)'}}>&nbsp;</a>
                            </li>
                            <li>
                                <a onClick={() => this.changeColor('pink')} title="theme-pink" href={this.state.href} data-color="pink"
                                   style={{backgroundColor: 'rgba(255, 94, 148, 1)'}}>&nbsp;</a>
                            </li>
                            <li>
                                <a onClick={() => this.changeColor('purple')} title="theme-purple" href={this.state.href} data-color="purple"
                                   style={{backgroundColor: 'rgba(196, 70, 218, 1)'}}>&nbsp;</a>
                            </li>
                            <li>
                                <a onClick={() => this.changeColor('red')} title="theme-red" href={this.state.href} data-color="red"
                                   style={{backgroundColor: 'rgba(232, 103, 107, 1)'}}>&nbsp;</a>
                            </li>
                        </ul>
                    </div>
                    <div className="clear">&nbsp;</div>
                    <h4>Change mods</h4>
                    <ul className="theme demo_list lights">

                        <li style={{width: '50%'}}>
                            <a onClick={() => this.changeMode("light")} className={this.state.isLight} href={this.state.href}  data-light="1">
                                <span className="light icon ion-ios-sunny">&nbsp;</span>
                                <strong>Light</strong>
                            </a>
                        </li>
                        <li style={{width: '50%'}}>
                            <a onClick={() => this.changeMode("dark")} className={this.state.isDark} href={this.state.href} data-light="2">
                                <span className="light icon ion-ios-moon">&nbsp;</span>
                                <strong>Dark</strong>
                            </a>
                        </li>
                    </ul>

                    <div className="clear">&nbsp;</div>
                </div>

                <audio id="sound" tabIndex="0" controls preload="auto">&nbsp;</audio>
            </aside>
        );
    }
}

export default Sidebar;
