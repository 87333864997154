import React from "react";

class Preloader extends React.Component {
    render() {
        return (
            <div>
                <div className="preloader">
                    <div className="centrize full-width">
                        <div className="vertical-center">
                            <div className="spinner">
                                <div className="double-bounce1">&nbsp;</div>
                                <div className="double-bounce2">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="background gradient">
                    <ul className="bg-bubbles">
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Preloader;
