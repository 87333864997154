import React from "react";
import $ from "jquery";
import MetaTags from 'react-meta-tags';
const IMG_URL = 'https://admin.hamazaspyan.xyz/images/clients/';

const _profileService = require("../../services/profile");

class About extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: false
        }
    }

    componentDidMount() {
        $('#profileLarge').show();
        this.getProfile();
    }

    getProfile = () => {
        _profileService.getProfileData()
            .then((response) => {
                if (response) {
                    const bd = new Date(response.data.birthday);
                    const today = new Date();
                    const diff = new Date(today.getTime() - bd.getTime());
                    const age = diff.getUTCFullYear() - 1970;

                    let state = {
                        status: true,
                        data: response.data,
                        age: age
                    };

                    this.setState(state);
                }
            })
    };

    renderServices = (data) => {
        return (
            <div className="row service-items border-line-v">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className="col col-d-6 col-t-6 col-m-12 border-line-h">
                                <div className="service-item">
                                    <div className="icon"><span className={item.icon}>&nbsp;</span></div>
                                    <div className="name">{item.title}</div>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    renderFacts = (data) => {
        return (
            <div className="row fuct-items">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className="col col-d-3 col-t-3 col-m-6 border-line-v">
                                <div className="fuct-item">
                                    <div className="icon"><span className={item.icon}>&nbsp;</span></div>
                                    <div className="name">{item.title}</div>
                                </div>
                            </div>
                        );
                    })
                }

                <div className="clear">&nbsp;</div>
            </div>
        );
    };

    renderClients = (data) => {
        return (
            <div className="row client-items">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className="col col-d-3 col-t-3 col-m-6 border-line-v">
                                <div className="client-item">
                                    <div className="image">
                                        <a rel="noopener noreferrer" target="_blank" href={item.url}>
                                        <img src={IMG_URL + item.logo} alt={item.name}/>
                                    </a></div>
                                </div>
                            </div>
                        );
                    })
                }
                <div className="clear"></div>
            </div>
        );
    };

    render() {
        return (
            <div className="card-inner animated fadeInLeft" id="card-home">
                {
                    this.state.status ?
                        <div className="card-wrap">
                            <MetaTags>
                                <title>Karen Hamazaspyan</title>
                                <meta property="og:title" content="Karen Hamazaspyan" />
                            </MetaTags>
                            <div className="content about">
                                <div className="title">About Me</div>
                                <div className="row">
                                    <div className="col col-d-6 col-t-12 col-m-12 border-line-v">
                                        <div className="text-box" dangerouslySetInnerHTML={{__html: this.state.data.overview}}>
                                        </div>
                                    </div>

                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="content services">
                                <div className="title">My Services</div>
                                {
                                    this.state.data.services ? this.renderServices(this.state.data.services) : null
                                }
                                <div className="clear">&nbsp;</div>
                            </div>
                            <div className="content fuct">
                                <div className="title">Fun Facts</div>
                                {
                                    this.state.data.facts ? this.renderFacts(this.state.data.facts) : null
                                }
                            </div>
                            <div className="content clients">
                                <div className="title">Clients</div>
                                {
                                    this.state.data.clients ? this.renderClients(this.state.data.clients) : null
                                }
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        );
    }
}

export default About;
