import React from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
import * as $ from 'jquery';
import MetaTags from "react-meta-tags";
window.$ = window.jQuery = require("jquery");

const mobile = require('is-mobile');

const _workService = require('../../services/works');
const env = require('../../config/config');
const IMAGES_URL = env.IMAGES_URL;

class Works extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: false
        }
    }

    componentDidMount() {
        if (mobile.isMobile()) {
            $('#profileLarge').hide();
        }
        this.getCategories();
        this.getWorks();
    }

    getCategories = () => {
        _workService.getData('category')
            .then((response) => {
                if (response) {
                    const state = {
                        status: true,
                        categories: response.data
                    };
                    this.setState(state);
                }
            })
    };

    getWorks = () => {
        _workService.getData('work')
            .then((response) => {
                if (response) {
                    const state = this.state;
                    state.works = response.data;
                    this.setState(state);
                }
            });
    };

    renderCategories = (data) => {
        $('.filter-button-group').on('click', '.f_btn', function () {
            const id = $(this).data('id');
            $('.filter-button-group .f_btn').removeClass('active');
            $(this).addClass('active');
            if(id !== 0) {
                $('.works .item_category').hide();
                $('.works .category_' + id).show();
            }
            else {
                $('.works .item_category').show();
            }

        });
        return (
            <div className="filter-menu filter-button-group">
                <div className="f_btn active" data-id={0}>
                    <label><input type="radio" name="fl_radio" value=".grid-item"/>All</label>
                </div>
                {
                    data.map((item, index) => {
                        let catClass = '.category_' + item.id;
                        return (
                            <div key={index} className="f_btn" data-id={item.id}>
                                <label>
                                    <input type="radio" name="fl_radio"
                                           value={catClass}/>{item.name}</label>
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    openWork = (work) => {
        const content = $('#popup-' + work.id)[0].innerHTML;
        $('#work_modal .mfp-content').html(content);
        $('#work_modal').css('overflow', 'hidden auto');
        $('#work_modal').show();
    };

    renderWorks = (data) => {

        return (
            <div className="row grid-items border-line-v">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index}
                                 className={'col col-d-6 col-t-6 col-m-12 border-line-h grid-item item_category category_' + item.category_id}>
                                <div className="box-item">
                                    <div className="image"><a onClick={() => {
                                        this.openWork(item)
                                    }} data-index={index} href={'#popup-' + item.id} className="has-popup-media"> <img
                                        width="600"
                                        height="500"
                                        src={IMAGES_URL + item.image}
                                        className="attachment-ryancv_600xauto size-ryancv_600xauto wp-post-image"
                                        alt=""
                                        srcSet={IMAGES_URL + item.image}/>/>
                                        <span className="info"> <span className="ion ion-search"></span> </span>
                                    </a>
                                    </div>
                                    <div className="desc">
                                        <a
                                        rel="noopener noreferrer" href={item.url} target="_blank"
                                        className="name">{item.name}</a>
                                    </div>

                                    <WorkModal item={item}/>

                                </div>
                            </div>
                        );
                    })
                }

                <div className="clear"></div>
            </div>
        );
    };

    render() {
        return (
            <div className="card-inner animated fadeInLeft" id="card-works">
                {
                    this.state.status ?
                        <div className="card-wrap">
                            <MetaTags>
                                <title>Works - Karen Hamazaspyan</title>
                                <meta property="og:title" content="Works - Karen Hamazaspyan" />
                            </MetaTags>
                            <div className="content works">
                                <div className="title">Recent Works</div>
                                {
                                    this.state.categories
                                        ?
                                        this.renderCategories(this.state.categories)
                                        : null
                                }

                                {
                                    this.state.works
                                        ?
                                        this.renderWorks(this.state.works)
                                        :
                                        null
                                }
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        );
    }
}

class WorkModal extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        $(".gallery-items").owlCarousel({
            items:1,
            margin:10,
            autoplay:true,
            loop:true,
            dots:false,
            nav: true,
        });
    }

    render() {
        return (
            <div id={'popup-' + this.props.item.id} className="popup-box mfp-fade mfp-hide">
                <div className="content">

                    <div className="desc">
                        <div className="post-box"><h2 className="h-title">{this.props.item.name}</h2>
                            <span className="blog-detail work-category">{this.props.item.category.name}</span>
                            <div className="blog-content" dangerouslySetInnerHTML={{__html: this.props.item.description}}>
                            </div>

                            <a rel="noopener noreferrer" href={this.props.item.url} target="_blank"
                               className="button"> <span
                                className="text">View Project</span>
                                <span className="arrow"></span> </a></div>
                            <hr/>
                            <div
                                className="gallery-items owl-theme owl-carousel">

                                    {
                                        this.props.item.images.map((image, index) => {
                                            return (
                                                <div key={index} className="item">
                                                    <img src={IMAGES_URL + image.name} alt={this.props.item.name}/>
                                                </div>
                                            );
                                        })
                                    }
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Works;
