import React from "react";
import Menu from "./Menu";
import logo from "../../assets/images/me.jpg";

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            href: '#',
            name: "Karen Hamazaspyan",
            subtitles: ['Full Stack Developer', 'Freelancer']
        }
    }



    render() {
        return (
            <header className="header">
                <div className="profile">
                    <div className="image">
                        <img alt={this.state.name}
                             src={logo}/>
                    </div>
                    <div className="title">{this.state.name}</div>
                    <div className="subtitle subtitle-typed">
                        <div className="typing-title">
                            {
                                this.state.subtitles.map(function (title, index) {
                                    return (
                                        <p key={index}>{title}</p>
                                    );
                                })
                            }
                        </div>
                    </div>

                </div>
                <span>&nbsp;</span>
                <a href={this.state.href} className="menu-btn"><span>&nbsp;</span></a>
                <Menu/>
            </header>
        );
    }
}

export default Header;
