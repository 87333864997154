import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Preloader from "./components/layouts/Preloader";
import Header from "./components/layouts/Header";
import Profile from "./components/layouts/Profile";
import Sidebar from "./components/layouts/Sidebar";
import Footer from "./components/layouts/Footer";
import About from "./components/pages/About";
import Resume from "./components/pages/Resume";
import Works from "./components/pages/Works";
import Blog from "./components/pages/Blog";
import Contacts from "./components/pages/Contacts";
import BlogItem from "./components/pages/Blogitem";
import $ from 'jquery';
import MetaTags from 'react-meta-tags';

class App extends React.Component {

    constructor(props) {
        super(props);

        const animations = 6;
        const default_config = {
            color: 'green',
            dark: 1
        };
        let config = localStorage.getItem('config');
        if (!config) {
            config = default_config;
            localStorage.setItem('config', JSON.stringify(default_config));
        } else {
            config = JSON.parse(config);
        }

        this.state = {
            animation: Math.floor(Math.random() * (animations) + 1),
            color: config.color,
            dark: config.dark,
            siteName: 'Karen Hamazaspyan'
        };

        const head = document.getElementsByTagName('head')[0];
        let dark = document.createElement('link');
        dark.id = 'dark';
        dark.rel = 'stylesheet';
        dark.type = 'text/css';
        dark.href = (config.dark === 1) ? 'assets/css/dark.css' : '';
        dark.media = 'all';
        head.appendChild(dark);

        let color = document.createElement('link');
        color.id = 'color_style';
        color.rel = 'stylesheet';
        color.type = 'text/css';
        color.href = 'assets/css/colors/' + config.color + '.css';
        color.media = 'all';
        head.appendChild(color);

        $('body').click(function (event) {
            if (!$(event.target).closest('#work_modal').length && !$(event.target).is('#work_modal')) {
                $('#work_modal .mfp-content').html('');
                $('#work_modal').hide();
            }
        });

    }

    closeModal = () => {
        $('#work_modal .mfp-content').html('');
        $('#work_modal').hide();
    };

    render() {

        import('./assets/css/animations/animation_' + this.state.animation + '.css');

        return (
            <BrowserRouter>
                <div>
                    <div id="work_modal"
                         className="mfp-wrap mfp-close-btn-in mfp-auto-cursor popup-box-inline mfp-ready">
                        <div className="mfp-container mfp-s-ready mfp-inline-holder">
                            <div className="mfp-content">

                            </div>
                            <button onClick={() => {
                                this.closeModal()
                            }} title="Close (Esc)" type="button" className="mfp-close">×
                            </button>
                        </div>
                    </div>
                    <div className="page page_wrap theme-style-classic">
                        <Preloader/>
                        <div className="container opened" data-animation-in="fadeInLeft"
                             data-animation-out="fadeOutLeft">
                            <Header/>
                            <div className="card-started" id="home-card">
                                <Profile/>
                            </div>
                            <div className="s_overlay">&nbsp;</div>
                            <div className="content-sidebar">
                                <div className="sidebar-wrap">
                                    <Sidebar/>
                                </div>
                                <span className="close">&nbsp;</span>
                            </div>
                            <MetaTags>
                                <meta name="description" content="Full Stack Developer" />
                                <meta property="og:description" content="Full Stack Developer" />
                                <meta property="og:image" content='' />
                                <meta name="keywords" content="Karen Hamazaspyan, Կարեն Համազասպյան,Karen, Hamazaspyan, Web development, Armenia, Web Developer Armenia, " />
                            </MetaTags>
                            <Switch>
                                <Route siteName={this.state.siteName} path="/" component={About} exact/>
                                <Route site_name={this.setState} path="/about" component={About}/>
                                <Route site_name={this.setState} path="/resume" component={Resume}/>
                                <Route site_name={this.setState} path="/works" component={Works}/>
                                <Route site_name={this.setState} path="/blog" component={Blog} exact/>
                                <Route site_name={this.setState} path="/blog/:id" component={BlogItem}/>
                                <Route site_name={this.setState} path="/contacts" component={Contacts}/>
                            </Switch>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
