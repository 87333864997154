import React from "react";
import {NavLink} from "react-router-dom";
import logo from "../../assets/images/me.jpg";

const _profileService = require("../../services/profile");

class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            href: '#',
            subtitles: ['Full Stack Developer', 'Freelancer']
        }
    }

    componentDidMount() {
        this.getProfileData();
    }

    getProfileData = () => {
        _profileService.getProfileData()
            .then((response) => {
                if (response) {
                    const state = {
                        name: response.data.name,
                        email: response.email,
                        social_networks: response.data.social_networks
                    };
                    this.setState(state);
                }
            })
    };

    render() {
        return (
            <div id="profileLarge" className="profile ">
                <div className="profile-content">
                    <div className="slide"
                         style={{backgroundImage: 'url(https://admin.hamazaspyan.xyz/images/wallpaper.jpg?v=1.0)'}}>&nbsp;</div>
                    <div className="image">
                        <img
                            src={logo}
                            alt={this.state.name}/>
                    </div>
                    <div className="title">{this.state.name}</div>
                    <div className="subtitle subtitle-typed">
                        <div className="typing-title">
                            {
                                this.state.subtitles.map(function (title, index) {
                                    return (
                                        <p key={index}>{title}</p>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="social">
                        {
                            this.state.social_networks ? this.state.social_networks.map(function (item, index) {
                                    return (
                                        <a key={index} rel="noopener noreferrer" target="_blank" href={item.link}>
                                            <span className={item.icon}> </span>
                                        </a>
                                    );
                                })
                                : null}
                    </div>

                </div>
                <div className="lnks">
                    <a rel="noopener noreferrer" target="_blank"
                       href="https://admin.hamazaspyan.xyz/cv/KarenHamazaspyan.pdf" className="lnk">
                        <span className="text">Download CV</span>
                        <span className="ion ion-archive"> </span>
                    </a>
                    <NavLink to="/contacts" className="lnk">
                        <span className="text">Contact Me</span>
                        <span className="ion ion-android-send"> </span>
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default Profile;
