import React from "react";
import {NavLink, withRouter} from "react-router-dom";

const MenuComponent = withRouter(props => <Menu {...props}/>);

class Menu extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            menus: [
                {
                    id: 1,
                    title: 'About',
                    path: '/',
                    class: (this.props.location.pathname === '/') ? 'current-menu-item' : '',
                    icon: 'icon ion-ios-person'
                },
                {
                    id: 2,
                    title: 'Resume',
                    path: '/resume',
                    class: (this.props.location.pathname === '/resume') ? 'current-menu-item' : '',
                    icon: 'icon ion-android-list'
                },
                {
                    id: 3,
                    title: 'Works',
                    path: '/works',
                    class: (this.props.location.pathname === '/works') ? 'current-menu-item' : '',
                    icon: 'icon ion-paintbrush'
                },
                {
                    id: 4,
                    title: 'Blog',
                    path: '/blog',
                    class: (this.props.location.pathname === '/blog') ? 'current-menu-item' : '',
                    icon: 'icon ion-chatbox-working'
                },
                {
                    id: 5,
                    title: 'Contact',
                    path: '/contacts',
                    class: (this.props.location.pathname === '/contacts') ? 'current-menu-item' : '',
                    icon: 'icon ion-at'
                }
            ]
        };
    }

    render() {
        return (
            <div className="top-menu top-menu-onepage">
                <div className="menu-main-menu-container">
                    <ShowMenus menus={this.state.menus} link={this.props.location.pathname}/>
                </div>
            </div>
        );
    }
}

function ShowMenus(props) {
    return (
        <ul id="menu-main-menu" className="menu">
            {
                props.menus.map(function (menu, index) {
                    return(
                        <li key={index} className={(props.link === menu.path) ? 'current-menu-item' : ''}>
                            <NavLink to={menu.path} className="one-page-menu-item">
                                <span className={menu.icon}>&nbsp;</span>
                                { menu.title }
                            </NavLink>
                        </li>
                    );
                })
            }
        </ul>

    );

}

export default MenuComponent;
